// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-citizenship-ae-tsx": () => import("./../../../src/pages/citizenship-ae.tsx" /* webpackChunkName: "component---src-pages-citizenship-ae-tsx" */),
  "component---src-pages-citizenship-br-tsx": () => import("./../../../src/pages/citizenship-br.tsx" /* webpackChunkName: "component---src-pages-citizenship-br-tsx" */),
  "component---src-pages-citizenship-cl-tsx": () => import("./../../../src/pages/citizenship-cl.tsx" /* webpackChunkName: "component---src-pages-citizenship-cl-tsx" */),
  "component---src-pages-citizenship-il-tsx": () => import("./../../../src/pages/citizenship-il.tsx" /* webpackChunkName: "component---src-pages-citizenship-il-tsx" */),
  "component---src-pages-citizenship-in-tsx": () => import("./../../../src/pages/citizenship-in.tsx" /* webpackChunkName: "component---src-pages-citizenship-in-tsx" */),
  "component---src-pages-citizenship-pt-br-tsx": () => import("./../../../src/pages/citizenship-pt-br.tsx" /* webpackChunkName: "component---src-pages-citizenship-pt-br-tsx" */),
  "component---src-pages-citizenship-tr-tsx": () => import("./../../../src/pages/citizenship-tr.tsx" /* webpackChunkName: "component---src-pages-citizenship-tr-tsx" */),
  "component---src-pages-citizenship-uk-tsx": () => import("./../../../src/pages/citizenship-uk.tsx" /* webpackChunkName: "component---src-pages-citizenship-uk-tsx" */),
  "component---src-pages-citizenship-us-tsx": () => import("./../../../src/pages/citizenship-us.tsx" /* webpackChunkName: "component---src-pages-citizenship-us-tsx" */),
  "component---src-pages-citizenship-za-tsx": () => import("./../../../src/pages/citizenship-za.tsx" /* webpackChunkName: "component---src-pages-citizenship-za-tsx" */),
  "component---src-pages-form-tsx": () => import("./../../../src/pages/form.tsx" /* webpackChunkName: "component---src-pages-form-tsx" */),
  "component---src-pages-webinar-tsx": () => import("./../../../src/pages/webinar.tsx" /* webpackChunkName: "component---src-pages-webinar-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

