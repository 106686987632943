import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import usTranslation from './extracted/us/translation.json';
import brTranslation from './extracted/br/translation.json';
import { DEFAULT_LANG } from '../constants';

const resources = {
  us: {
    translation: usTranslation,
  },
  br: {
    translation: brTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  returnEmptyString: false,
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
